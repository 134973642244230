<!-- 收费配置 -->

<template>
  <div class="role main-cnt">
    <div class="content">
      <!-- <common-table ref="chargeTable" tableHeight="calc(100vh - 325px)" :ischeck="false" :ispaging="true"
        :apiName="BasicApi.roleList" :filters="filters" :columns="tableColumns" @associaBtn="onAssociaBtn">
      </common-table> -->
    </div>

    <!-- 关联电站弹框 -->
    <w-dialog ref="refDialog" class="add-dialog" title="关联电站" width="38%" btnWidth="140px" top="20vh"
      @wConfirm="addConfirm" :hideFooter="true">
      <div class="msg-title">已关联电站({{ totals }})</div>
      <!-- <common-table ref="associateTable" :ischeck="true" :ispaging="false" :apiName="BasicApi.gateList"
        :columns="associateColumns">
      </common-table> -->

      <div class="associate-btn">
        <el-button class="confirm" @click="onCloseBtn" round>关闭</el-button>
      </div>
    </w-dialog>
  </div>
</template>

<script setup>
  import { ref, onMounted, computed, watch } from "vue";
  // import { BasicApi } from "@/plugins/api.js";
  // import { ElMessage } from "element-plus";
  import { useStore } from "vuex";

  const store = useStore();
  const menuTokens = computed(() => store.state.menuToken.menuTokens);
  const authData = ref([]);
  watch(
    () => menuTokens.value,
    (data) => {
      if (data.length) {
        authData.value = data;
      }
    },
    {
      deep: true,
      immediate: true,
    }
  );
  /** 表格对象 */
  const chargeTable = ref(null);
  /** 筛选条件列表 */
  // const filters = ref([
  //   {
  //     name: "keyword",
  //     filterType: "search",
  //     value: "",
  //     placeholder: "请输入充电桩名称、编号",
  //   },
  // ]);
  /** 表格配置数据 */
  // const tableColumns = ref([
  //   {
  //     prop: "nr_name",
  //     label: "规则名称",
  //     color: "--text-color",
  //   }, {
  //     prop: "nr_name",
  //     label: "服务费",
  //     color: "--text-color",
  //   },
  //   {
  //     prop: "nr_name",
  //     label: "电费",
  //     color: "--text-color",
  //   },
  //   {
  //     type: "operation",
  //     prop: "",
  //     label: "操作",
  //     minWidth: 200,
  //     bottons: [
  //       {
  //         name: "关联",
  //         action: "associaBtn",
  //         token: "r_wSAPoQp3ZiXvmqU2O5Vz8tc1CMhg",
  //       }
  //     ],
  //   },
  // ]);
  // 关联电站表格配置
  // const associateColumns = ref([
  //   {
  //     prop: "nr_name",
  //     label: "充电站名称",
  //     color: "--text-color",
  //   },
  //   {
  //     prop: "nr_name",
  //     label: "区域",
  //     color: "--text-color",
  //   },
  // ]);
  const totals = ref(0);
  /** 当前操作 行数据 */
  // const currentRow = ref(null);
  /**
   * 
   * 关联按钮
   * 
   * */
  // const onAssociaBtn = (row) => {
  //   currentRow.value = row;
  //   refDialog.value.show();
  // };
  /**
   * 
   * 关闭按钮
   * 
   * */
  const onCloseBtn = () => {
    refDialog.value.close();
  }



  /** 表格编辑 */


  /** 新增/编辑弹框 */
  const refDialog = ref(null);

  /** 角色新增/编辑 确认 */
  const addConfirm = () => {
    //     refDialog.value.isLoading = true;
    //     let url = "roleAdd";
    //     BasicApi[url](data).then((res) => {
    //       refDialog.value.isLoading = false;
    //       if (res.Code === 200) {
    //         ElMessage.success("新增角色成功！");
    //         refDialog.value.close();
    //         // 重新获取角色列表
    //         chargeTable.value.tableLoad();
    //       } else {
    //         let msg = res.Message ? res.Message : "新增角色失败！";
    //         ElMessage.error(msg);
    //       }
    //     });
  };

  onMounted(() => {
    chargeTable.value.tableLoad();
  });
</script>

<style lang="scss">
  .confirm {
    color: #ffffff;
  }

  .associate-btn {
    text-align: center;
    margin-top: 20px;
  }

  .msg-title {
    font-weight: bold;
    font-size: 15px;
    color: var(--text-color);
    padding: 15px 0;
  }

  .el-button {
    width: 100px;
    background-color: #fff;
    border: 1px solid var(--theme-color);
    color: var(--theme-color);

    &:nth-child(1) {
      background-color: var(--theme-color);
      color: #fff;
    }
  }

  .role {
    font-family: "Source Han Sans CN";

    .content {
      padding: 20px;
    }

    .add-dialog {
      .el-dialog {
        min-width: 840px;

        .el-dialog__body {
          padding: 0 15px 60px;

          .add-form {
            display: flex;
            flex-wrap: wrap;

            .el-form-item {
              width: 33.3%;
              padding: 20px 15px 0;
              margin-bottom: 0;

              .el-form-item__content {
                .el-select {
                  width: 100%;

                  .el-input {
                    width: 100%;
                  }
                }
              }
            }

            .el-form-item.desc {
              width: 66.7%;
            }

            .el-form-item.block {
              width: 100%;
            }

            .el-divider {
              margin: 30px 0 0 15px;
              width: calc(100% - 30px);
            }
          }
        }
      }
    }

    .auth-dialog {
      .el-dialog {
        min-width: 840px;

        .el-dialog__body {
          padding: 0;
        }
      }
    }
  }
</style>